// Here you can add other styles
// .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
//     resize: horizontal;
//     overflow-x: auto;
//     overflow-y: auto;
//     text-overflow: ellipsis;

// }

.k-grid td {
  white-space: nowrap;
  background-color: "white";
}

.k-grid {
  border: none;
  background-color: #424242;
}

.k-grid-header {
  border: none;
  background-color: #424242;
}

.k-virtual-content {
  overflow-y: unset !important;
  margin-right: 17px;
  -webkit-overflow-scrolling: touch;
}

.k-grid-container td {
  color: white;
  background-color: #424242;
}

.k-grid th {
  font-size: large;
  color: #1ba9ee;
  background-color: #424242;
}

.bylocation.k-grid th {
  font-size: large;
  color: #d9d9d9 !important;
  background-color: #424242;
}

.k-grid-content {
  background-color: #424242;
}

.byreaderId.k-grid th,
.byreaderId.k-grid td {
  text-align: center;
}

.byreaderId .k-grid-content {
  margin-bottom: 0.5rem;
}

.k-grid button {
  color: #1ba9ee !important;
}

.k-grid button:hover {
  font-size: large;
  color: #fff !important;
}

.k-grid a {
  text-decoration: underline !important;
}

.k-grid a:hover {
  color: #1ba9ee;
}

th .k-i-sort-asc-sm,
.k-i-sort-desc-sm {
  margin-top: 5px;
}

span[class*="MuiTabs-indicator-"] {
  background-color: #90cd4f !important;
}

.tabsDashbord {
  background: #424242;
}

.c-header div[class*="MuiGrid-item"] {
  padding: 12px;
}

header button {
  padding: 0 !important;
  margin: 0 !important;
}

header a {
  padding: 12px 35px !important;
  text-decoration: none !important;
  color: #fff !important;
  margin: 0 !important;
}

header {
  background-color: black !important;
  border: none !important;
  color: white !important;
}

header .header-span-title {
  color: #90cd4f !important;
}

header .header-company-title {
  font-size: 24px !important;
}

header .header-userinfo {
  color: #1ba9ee !important;
  line-height: 35px !important;
}

.color-blue {
  color: #1ba9ee !important;
}

header .header-nav-menu1 {
  float: right;
  line-height: 35px;
}

.MuiAppBar-root-132 {
  background: #262626 !important;
}

.MuiGrid-spacing-xs-3-54 {
  margin: 0px !important;
}

form .login-span-title {
  color: #90cd4f !important;
  font-size: 30px !important;
}

form .login-company-title {
  font-size: 36px !important;
}

.form-login input,
.form-login button {
  padding: 10px !important;
  text-transform: none !important;
  background-color: white;
  color: black;
  font-size: 18px !important;
  border: 2px solid;
}

.form-login .user-input input {
  border: 2px solid;
  border-end-start-radius: 10px;
  border-start-start-radius: 10px;
}

.form-login .MuiFormLabel-root.Mui-error {
  top: -5 !important;
  bottom: 5 !important;
}

.form-login .MuiInput-underline:after {
  border-bottom: none !important;
}

.form-login .MuiInput-underline:before {
  border-bottom: none !important;
}

.form-login .MuiInput-underline:hover {
  border-bottom: none !important;
}

.form-login {
  border-bottom: none !important;
}

.form-login button {
  border: 2px solid #92d050 !important;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-start-end-radius: 10px !important;
  border-end-end-radius: 10px !important;
  background-color: #92d050 !important;
  line-height: 1.1 !important;
  color: white !important;
  min-width: 90px !important;
}

.form-login .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  color: white !important;
}

div[role="progressbar"] {
  color: #92d050 !important;
}

.c-app.login {
  background: linear-gradient(black,
      black,
      black,
      black,
      black,
      rgb(63, 63, 63) 95%,
      black);
}

.dashbord-toolbar select[class*=" MuiSelect-select-"] {
  padding: 10px !important;
  background-color: #262626 !important;
}

.dashbord-toolbar input#filterSelect {
  padding: 10px !important;
  background-color: #262626 !important;
  color: #fff;
  text-align: left !important;
  border-radius: 0;
  text-transform: capitalize;
}

.dashbord-toolbar select[class*=" MuiSelect-select-"]:focus {
  background-color: #262626 !important;
}

.dashbord-toolbar div[class*=" MuiInput-underline-"]:before {
  border: none !important;
}

.dashbord-toolbar div[class*=" MuiInput-underline-"]:after {
  border: none !important;
}

div[class*=" MuiInput-underline-"]:after {
  border-bottom: 2px solid #1ba9ee;
}

label.Mui-focused {
  color: #1ba9ee !important;
}

.Mui-focused {
  color: #fff !important;
}

.dashbord-toolbar select[class*=" MuiSelect-select-"]:hover {
  border: none !important;
}

button[class*=" MuiButton-containedPrimary"] {
  background-color: #1ba9ee !important;
}

.dashbord .filterDates input {
  color: black;
  background-color: #fff;
  padding-left: 6px;
}

.dashbord .filterDates label {
  color: #1ba9ee;
}

.dashbord-report div[class*="MuiCardHeader-root"] {
  padding-bottom: 0;
}

.filterDashbord p {
  cursor: pointer;
}

.right-dialog div[role*="presentation"] {
  float: right;
}

.date-range,
.date-range input {
  background: #262626 !important;
  cursor: pointer !important;
}

.same-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.same-height-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.c-header-toggler {
  display: none;
}

// button[class*=" MuiButton-contained"]{
//     background-color: #6f6f6f !important;
//     color: white !important;
// }

header a {
  padding: 12px 25px !important;
}

.dashboard-view-toggel-btn {
  background-color: #7c899a !important;
  color: white !important;
  border-radius: 0px !important;
}

.refresh-button {
  background-color: #6f6f6f !important;
  color: white !important;
}

.refresh-button svg {
  color: black !important;
  font-weight: 700 !important;
  margin-right: 5px;
}

button {
  font-weight: 100 !important;
}

.graphical-box {
  height: 850px;
  width: 1210px;
  position: relative;
  overflow: auto;
  background-color: #fff !important;
  background-size: contain !important;
}

.graphical-box .graphical-inner-box {
  position: absolute;
  background: #fefe00;
  font-weight: 700;
  text-align: center;
  float: left;
  color: #000;
  cursor: move;
  width: 23px;
  height: 23px;
}

.graphical-inner-box.non-draggable {
  cursor: pointer !important;
}

.delete-tag {
  position: fixed !important;
  top: -8px;
  right: -8px;
  color: #ff0000 !important;
  padding: 0px !important;
}

.delete-tag svg {
  font-size: 16px;
}

.delete-tag:hover svg {
  color: #000(255, 55, 55);
  font-size: 20px;
}

area {
  border: 3px solid red !important;
}

map {
  background: #fff;
}

area::before {
  content: "Read this -";
}

/* barcode container */
.anim-box {
  position: absolute;
  top: 8%;
  width: 100%;
  height: 735px;
}

/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box> :first-child:before,
.anim-box> :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: #90cd4f;
  border-style: solid;
  content: " ";
  margin: 5px;
}

/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 10px 0 0 10px;
}

/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 10px 10px 0 0;
}

/* bottom right corner */
.anim-box> :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 10px 10px 0;
}

/* bottom left corner */
.anim-box> :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 10px 10px;
}

/* barcode bars */
.anim-item {
  display: inline-block;
  background-color: white;
  height: 100px;
}

.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
  width: 1150px;
  margin-left: 30px;
  height: 3px;
  background-color: #90cd4f;
  opacity: 0.7;
  position: absolute;
  box-shadow: 0px 0px 8px 10px rgba(56, 80, 31, 0.49);
  top: 50%;
  animation-name: scan;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

.removeTrasnferButton {
  background-color: red !important;
  color: white !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  margin-left: 10px !important;
}

.removeTrasnferButton:hover {
  background-color: darkred !important;
}

.addTrasnferButton {
  background-color: #1aaceb !important;
  color: white !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 50% !important;
  padding: 0 !important;
}

.addTrasnferButton:hover {
  background-color: #125775 !important;
}


@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(56, 80, 31, 0.49);
    top: 50%;
  }

  25% {
    box-shadow: 0px 6px 8px 10px rgba(56, 80, 31, 0.49);
    top: 5px;
  }

  75% {
    box-shadow: 0px -6px 8px 10px rgba(56, 80, 31, 0.49);
    top: 98%;
  }
}

/* MEDIA QUERY FOR RESPONSIVE VIEW */
@media (min-width: 768px) {
  .c-main>.container-fluid {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .header-company-name {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .header-company-logo {
    text-align: center;
    padding-bottom: 0 !important;
  }

  .header-userinfo {
    float: right;
  }

  .header-company-name {
    padding-bottom: 0 !important;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  border-radius: 50%;
  /* Optional: make it circular */
  width: 20px;
  /* Adjust the width if necessary */
  height: 20px;
  /* Adjust the height if necessary */
  filter: brightness(0) invert(1);
  /* Ensure it's white */
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
  background-color: transparent !important;
}

.k-grid tbody tr,
.k-grid tbody tr {
  background-color: transparent !important;
}